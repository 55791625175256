<template>
  <div
    :class="[
      'table-container',
      { 'logged-in': !isMobile, 'not-logged-in': isMobile },
    ]"
  >
    <div
      class="tables-wrapper"
      :style="{
        justifyContent:
          banksList === null || banksList?.length == 0
            ? 'center'
            : 'flex-start',
      }"
    >
      <div class="admin-options" v-if="!showTable">
        <div class="options-wrapper">
          <div class="options" @click="navigateToAllBanks">
            <div class="tag">Entity</div>
            <div class="description">Create, Edit Entity and Branches</div>
          </div>
          <div class="options" @click="openAddBankUserModal">
            <div class="tag">Onboard First User</div>
            <div class="description">
              Associate an existing registered user to a entity
            </div>
          </div>
          <div class="options" @click="openSetSupervisorModal">
            <div class="tag">Select Supervisor for any existing user</div>
            <div class="description">
              Set or Change supervisor for any existing entity user
            </div>
          </div>
        </div>
        <div class="options-wrapper">
          <div class="options" @click="openBulkUserUpload">
            <div class="tag">Bulk User Upload</div>
            <div class="description">
              Create multiple new users for entity through an excel file upload
            </div>
          </div>
          <div class="options" v-if="enableBulkOrder" @click="openBulkOrderUpload">
            <div class="tag">Create Bulk Orders</div>
            <div class="description">Place bulk order for entity through an excel file upload</div>
          </div>
          <div class="options" v-if="!enableBulkOrder" style="background: white; cursor: default">
          </div>
          <div class="options" style="background: white; cursor: default">
            <div class="tag"></div>
            <div class="description"></div>
          </div>
        </div>
      </div>
      <div class="table-wrapper" v-if="showTable">
        <div class="table-title-wrapper">
          <div class="wrapper">
            <div class="back-button" @click="navigateToMainMenu">
              <i
                class="fa-solid fa-arrow-left fa-xl"
                style="color: #11365a"
              ></i>
            </div>
            <div class="table-title">Entities</div>
          </div>
          <div
            class="view-all-button"
            v-if="
              banksList?.length > 0
            "
            @click="AddNewBank()"
          >
            Create New
          </div>
        </div>
        <div class="search-bank-wrapper" v-if="!selectSupervisor">
          <input
            type="email"
            placeholder="Search entity by name"
            class="invitee-email"
            style="width: 70%"
            autocomplete="off"
            id="bankName"
            v-model="bankName"
            for="bankName"
            @keydown="debouncedSearch"
          />
          <button
            type="submit"
            class="save-button"
            :style="{
              opacity: searchButton ? '0.5' : '1',
              cursor: searchButton ? 'not-allowed' : 'pointer',
              width: '30%',
              alignSelf: 'center',
            }"
            :disabled="searchButton"
            @click="searchBank()"
          >
            Search
          </button>
        </div>
        <!-- Desktop -->
        <table
          id="searchResultTable"
          v-if="!mobile && banksList && banksList?.length > 0"
        >
          <thead style="background: #ecf4ff">
            <tr>
              <th v-for="col in tabelColumns" :key="col">
                {{ col }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="bankDetails in banksList" :key="bankDetails?.id">
              <td>
                {{ bankDetails?.name }}
              </td>
              <td>
                {{ bankDetails?.type }}
              </td>
              <td>
                {{
                  bankDetails?.countryName !== null &&
                  bankDetails?.countryName !== ""
                    ? bankDetails?.countryName
                    : "-"
                }}
              </td>
              <td>
                {{ bankDetails?.rmName }}
              </td>
              <td class="action-buttons">
                <span class="button" @click="editBank(bankDetails)">Edit</span
                ><span>|</span
                ><span class="button" @click="ListofBranches(bankDetails)"
                  >View Branches</span
                >
              </td>
            </tr>
          </tbody>
        </table>
        <div
          class="separation-line"
          v-if="!mobile && banksList?.length > 0"
        ></div>
        <!-- Mobile -->
        <div
          id="searchResultDiv"
          v-if="mobile && banksList && banksList?.length > 0"
        >
          <div
            v-for="(bankDetails, index) in banksList"
            :key="index"
            class="result-wrapper"
          >
            <div class="row">
              <div class="label">Entity Name:</div>
              <div class="value">{{ bankDetails?.name }}</div>
            </div>
            <div class="row">
              <div class="label">Entity Type:</div>
              <div class="value">{{ bankDetails?.type }}</div>
            </div>
            <div class="row">
              <div class="label">Country:</div>
              <div class="value" style="text-transform: capitalize;">
                {{
                  bankDetails?.countryName !== null &&
                  bankDetails?.countryName !== ""
                    ? bankDetails?.countryName
                    : "-"
                }}
              </div>
            </div>
            <div class="row">
              <div class="label">Relationship Manager:</div>
              <div class="value" style="text-transform: capitalize;">
                {{ bankDetails?.rmName }}
              </div>
            </div>
            <div class="check-status">
              <span class="button color1" @click="editBank(bankDetails)">Edit</span
              ><span class="button color2" @click="ListofBranches(bankDetails)"
                >View Branches</span
              >
            </div>
          </div>
        </div>
      </div>
      <div
        class="no-data-wrapper"
        v-if="
          (showTable && banksList === null) ||
          (showTable && banksList === undefined) ||
          (showTable && banksList?.length == 0)
        "
      >
        <p class="no-data">No data found</p>
        <div class="view-all-button" @click="AddNewBank()">Create New</div>
      </div>
    </div>
  </div>
  <Toastify ref="toastContainer" />
  <EditBank
    v-if="showEditBank"
    :bankDetails="selectedBank"
    :newBank="addBank"
    @close="hideEditBank()"
  />
  <ViewBranches
    v-if="showBranches"
    :bankDetails="selectedBank"
    @close="hideBranchesList()"
  />
  <AddBankUser v-if="showAddBankUser" @close="hideAddBankUser()" />
  <SetSupervisor v-if="showSetSupervisor" @close="hideSetSupervisor()" />
  <BulkUserUploadModal
    v-if="showBulkUserUpload"
    @close="hideBulkUserUpload()"
  />
  <BulkOrderUploadModal
    v-if="showBulkOrderUpload"
    @close="hideBulkOrderUpload()"
    @placeManualOrder="showManualOrderModal"
  />
  <RmManualOrder
    v-if="showManualOrder"
    :hideManualOrderModal="hideManualOrderModal"
    :selectedBankId="selectedBankId"
  />
</template>
<script>
// import VueCookies from "vue-cookies";
import Toastify from "@/components/Toastify.vue";
import EditBank from "@/views/Modals/InternalUserModals/EditBank.vue";
import ViewBranches from "@/views/Modals/InternalUserModals/ViewBranches.vue";
import { GetBanksList } from "@/api/APIs";
import AddBankUser from "@/views/Modals/InternalUserModals/AddBankUser.vue";
import SetSupervisor from "@/views/Modals/InternalUserModals/SetSupervisor.vue";
import BulkUserUploadModal from "@/views/Modals/InternalUserModals/BulkUserUpload.vue";
import debounce from "lodash/debounce";
import BulkOrderUploadModal from "@/views/Modals/InternalUserModals/RmBulkOrder.vue";
import RmManualOrder from "../Modals/InternalUserModals/RmManualOrder.vue";

export default {
  name: "BanksAndHq",
  components: {
    Toastify,
    EditBank,
    ViewBranches,
    AddBankUser,
    SetSupervisor,
    BulkUserUploadModal,
    BulkOrderUploadModal,
    RmManualOrder,
  },
  data() {
    return {
      isMobile: window.innerWidth < 1023,
      mobile: window.innerWidth <= 767,
      tabelColumns: ["Entity Name", "Entity Type", "Country", "Relationship Manager", "Actions"],
      banksList: null,
      showEditBank: false,
      showBranches: false,
      selectedBank: null,
      addBank: false,
      showAddBankUser: false,
      showSetSupervisor: false,
      showBulkUserUpload: false,
      showBulkOrderUpload: false,
      bankName: "",
      searchButton: false,
      debouncedSearch: debounce(this.handleKeyDown, 300),
      enableBulkOrder: process.env.VUE_APP_ENABLE_BULK_ORDER === "true",
      showManualOrder: false,
      selectedBankId: null,
    };
  },
  computed: {
    showTable() {
      return this.$route.query.allbanks === "true";
    },
  },
  mounted() {
    this.BanksList();
    window.addEventListener("resize", this.handleResponsive);
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.handleResponsive);
  },
  methods: {
    handleKeyDown() {
      if (this.bankName.trim().length === 0) {
        this.searchBank();
      }
      if (this.bankName.trim().length >= 2) {
        this.searchBank();
      }
    },
    searchBank() {
      this.searchButton = true;
      this.BanksList();
      this.searchButton = false;
    },
    async BanksList() {
      try {
        const response = await GetBanksList(this.bankName);
        if (response) {
          this.banksList = response?.allEntitiesResults;
        } else {
          this.$refs.toastContainer.addToast(response.message, {
            type: "error",
          });
        }
      } catch (error) {
        if (error.response && error.response.status !== 403) {
          return this.$refs.toastContainer.addToast(
            "Something went wrong. Please try again later.",
            { type: "error" }
          );
        }
      }
    },
    handleResponsive() {
      this.isMobile = window.innerWidth < 1023;
    },
    navigateToAllBanks() {
      this.$router.push({ query: { allbanks: "true" } });
    },
    navigateToMainMenu() {
      this.$router.push({ query: {} });
    },
    openAddBankUserModal() {
      this.showAddBankUser = true;
    },
    hideAddBankUser() {
      this.showAddBankUser = false;
    },
    openSetSupervisorModal() {
      this.showSetSupervisor = true;
    },
    hideSetSupervisor() {
      this.showSetSupervisor = false;
    },
    openBulkUserUpload() {
      this.showBulkUserUpload = true;
    },
    openBulkOrderUpload() {
      this.showBulkOrderUpload = true;
    },
    hideBulkUserUpload() {
      this.showBulkUserUpload = false;
    },
    hideBulkOrderUpload() {
      this.showBulkOrderUpload = false;
    },
    editBank(bankDetails) {
      this.selectedBank = bankDetails;
      this.addBank = false;
      this.showEditBank = true;
    },
    hideEditBank() {
      this.selectedBank = null;
      this.addBank = false;
      this.showEditBank = false;
      this.BanksList();
    },
    AddNewBank() {
      this.addBank = true;
      this.showEditBank = true;
    },
    ListofBranches(bankDetails) {
      this.selectedBank = bankDetails;
      this.showBranches = true;
    },
    hideBranchesList() {
      this.selectedBank = null;
      this.showBranches = false;
    },
    showManualOrderModal(id) {
      this.selectedBankId = id;
      this.showBulkOrderUpload = false;
      this.showManualOrder = true;
    },
    hideManualOrderModal() {
      this.showManualOrder = false;
    },
  },
};
</script>
<style scoped>
.table-container,
.table-container * {
  box-sizing: border-box;
}
.table-container {
  padding-top: 2rem;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-wrap: wrap;
  min-height: 95vh;
}
.logged-in {
  margin-left: 18vw;
  padding-top: 55px;
}

.not-logged-in {
  width: 100%;
}
.no-data-wrapper {
  display: flex;
  flex-direction: column;
  align-self: stretch;
  align-items: center;
  justify-content: center;
  height: 100%;
}
.tables-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 95%;
  padding: 2rem 1.5rem;
  border-radius: 16px;
  background-color: white;
  gap: 2rem;
  margin-top: 2rem;
  /* align-self: stretch; */
}
.admin-options {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  align-self: stretch;
}
.options-wrapper {
  display: flex;
  align-items: center;
  gap: 20px;
  align-self: stretch;
}
.options {
  display: flex;
  padding: 30px 24px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  flex: 1 0 0;
  border-radius: 20px;
  background: #ecf4ff;
  align-self: stretch;
  cursor: pointer;
  min-height: 22vh;
}
.options:hover {
  background: #dee8f6;
}
.tag {
  color: #11365a;
  font-family: "Montserrat-Regular", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px;
  letter-spacing: -0.16px;
  align-self: stretch;
  text-align: left;
}
.description {
  color: #11365a;
  font-family: "Montserrat-Regular", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: -0.16px;
  align-self: stretch;
  text-align: left;
}
.table-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  gap: 1.5rem;
}
.wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 30px;
}
.table-title-wrapper {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  justify-content: space-between;
}
.table-title {
  color: #11365a;
  font-family: "Montserrat-Regular", sans-serif;
  line-height: 180%;
  text-align: left;
  font-size: 26px;
  font-weight: 600;
}
.view-all-button {
  border-radius: 13px;
  border: 2px solid #11365a;
  padding: 8px 36px;
  text-align: center;
  font-family: "Montserrat-Regular", sans-serif;
  color: #11365a;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
}
#searchResultTable {
  width: 100%;
  border-collapse: collapse;
  background-color: #ffffff;
  border-radius: 16px;
  overflow: hidden;
  font-family: "Montserrat-Regular", sans-serif;
}
#searchResultTable th {
  text-align: left;
  font-family: "Montserrat-Regular", sans-serif;
  color: #11365a;
  padding: 10px 16px;
  min-width: 30px;
}
.no-data {
  text-align: center;
  font-family: "Montserrat-Regular", sans-serif;
  color: #11365a;
  font-size: 16px;
  font-weight: 500;
}
#searchResultTable td {
  text-align: left;
  padding: 10px 16px;
  color: #11365a;
  font-family: "Montserrat-Regular", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 175%;
  letter-spacing: -0.16px;
}
.action-buttons {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 10px;
}
.button {
  cursor: pointer;
}
.separation-line {
  width: 100%;
  height: 2px;
  opacity: 0.2;
  background: #3e4855;
}
.search-bank-wrapper {
  display: flex;
  align-items: center;
  gap: 20px;
  align-self: stretch;
}
.invitee-email {
  padding: 11px 24px;
  align-self: stretch;
  border-radius: 16px;
  border: 1px solid #11365a;
  color: #11365a;
  font-family: "Montserrat-Medium", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
  letter-spacing: -0.16px;
  outline: none;
}
.save-button {
  display: flex;
  width: 50%;
  align-self: stretch;
  padding: 12px 26px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 16px;
  background: var(--Dark-Blue, #11365a);
  color: #fff;
  text-align: center;
  font-family: "Montserrat-Regular", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  letter-spacing: -0.16px;
  height: 52px;
  outline: none;
  border: none;
}
.save-button:hover,
.cancel-button:hover {
  box-shadow: 0px 0px 20px 4px #cbe5ff;
}

@media only screen and (max-width: 767px) {
  .search-bank-wrapper {
    flex-direction: column;
    gap: 10px;
  }
  .invitee-email {
    padding: 10px 20px;
    width: 100% !important;
  }
  .invitee-email:first-of-type {
    line-height: 20.5px;
  }
  .save-button,
  .cancel-button {
    height: 42px;
  }
  .save-button {
    width: 100% !important;
  }
  .options-wrapper {
    flex-direction: column;
  }
  .options {
    min-height: auto;
  }
  .table-container {
    padding-top: 1rem;
  }
  .tables-wrapper {
    padding: 2rem 1rem;
    margin-top: 0;
  }
  .table-wrapper {
    gap: 1rem;
  }
  #searchResultDiv {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    align-self: stretch;
    width: 100%;
    gap: 20px;
  }
  .result-wrapper {
    display: flex;
    padding: 21.634px;
    flex-direction: column;
    align-items: flex-start;
    gap: 15px;
    align-self: stretch;
    border-radius: 21.634px;
    border: 1.352px solid rgba(0, 0, 0, 0.15);
    background: #fff;
  }
  .row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
    color: #11365a;
    gap: 20px;
  }
  .label {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    align-self: stretch;
    color: #11365a;
    font-family: "Montserrat-Regular", sans-serif;
    text-align: left;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    /* line-height: 37.859px; */
    letter-spacing: -0.216px;
    width: auto;
  }
  .value {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    align-self: stretch;
    color: #11365a;
    font-family: "Montserrat-Regular", sans-serif;
    text-align: left;
    font-size: 16px;
    font-style: normal;
    /* font-weight: 400; */
    letter-spacing: -0.216px;
    flex: 1;
  }
  .check-status {
    display: flex;
    flex-direction: column;
    align-self: stretch;
    align-items: center;
    justify-content: center;
    width: 100%;
    border-radius: 10.817px;
    border: none;
    color: #11365a;
    font-family: "Montserrat-Regular", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 37.859px;
    letter-spacing: -0.216px;
    gap: 10px;
  }
  .color1 {
    border: 1px solid #11365A;
    border-radius: 8px;
    align-self: stretch;
    line-height: 27px;
  }
  .color2 {
    background: #11365A;
    color: white;
    border-radius: 8px;
    align-self: stretch;
    line-height: 29px;
  }
  .table-title {
    font-size: 20px;
  }
  .view-all-button {
    font-size: 14px;
    padding: 8px 20px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1156px) {
  .tables-wrapper {
    margin-top: 0;
  }
}
@media only screen and (min-width: 1156px) {
}
</style>
