import axios from "axios";
import VueCookies from "vue-cookies";
import store from "@/store";

const baseURL = process.env.VUE_APP_API_HOST;

const instance = axios.create({
  baseURL: baseURL,
});

instance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response && error.response.status === 403) {
      store.dispatch("processLogout");
    }
    return Promise.reject(error);
  }
);

export const Login = async (email, password) => {
  try {
    let payload = {
      email: email,
      password: password,
    };
    const response = await instance.post("/user/login", payload);
    return response.data;
  } catch (error) {
    console.log("Error fetching assets:", error);
    throw error;
  }
};

export const ContactUs = async (payload) => {
  const authToken = JSON.stringify({
    email: VueCookies.get("email"),
    webToken: VueCookies.get("token"),
  });
  try {
    const response = await instance.post("/user/contact-us", payload, {
      headers: {
        "auth-token": authToken,
      },
    });
    return response.data;
  } catch (error) {
    console.log("Error fetching assets:", error);
    throw error;
  }
};

export const OTPVerification = async (email, token, otp) => {
  try {
    let payload = {
      email: email,
      secretValue: token,
      code: otp,
    };
    const response = await instance.post("/user/verify", payload);
    return response.data;
  } catch (error) {
    console.log("Error fetching assets:", error);
    throw error;
  }
};

export const Signup = async (name, email, password, enteredReferralCode) => {
  try {
    let payload = {
      name: name,
      email: email,
      password: password,
      referralCode: enteredReferralCode,
    };
    const response = await instance.post("/user/register", payload);
    return response.data;
  } catch (error) {
    console.log("Error fetching assets:", error);
    throw error;
  }
};

export const UserDetails = async (email, token) => {
  try {
    const authToken = JSON.stringify({ email: email, webToken: token });

    const response = await instance.post("/user/token", null, {
      headers: {
        "auth-token": authToken,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching assets:", error);
    throw error;
  }
};

export const Logout = async (email, password) => {
  try {
    let payload = {
      email: email,
      password: password,
    };
    const response = await instance.post("/user/logout", payload);
    return response.data;
  } catch (error) {
    console.log("Error fetching assets:", error);
    throw error;
  }
};

export const PaymentConfirm = async (payload) => {
  try {
    const response = await instance.post("/payment/success", payload);
    return response.data;
  } catch (error) {
    console.log("Error fetching assets:", error);
    throw error;
  }
};

export const PaymentRetry = async (orderEmail, paymentSecret, doNewPayment) => {
  try {
    let payload = {
      orderEmail: orderEmail,
      paymentSecret: paymentSecret,
      doNewPayment: doNewPayment,
    };
    const response = await instance.post("/payment/retry", payload);
    return response.data;
  } catch (error) {
    console.log("Error fetching assets:", error);
    throw error;
  }
};

export const PaymentNoSuccess = async (orderEmail, paymentSecret, doNewPayment) => {
  try {
    let payload = {
      orderEmail: orderEmail,
      paymentSecret: paymentSecret,
      paymentStatus: "CANCELLED",
      doNewPayment: doNewPayment,
    };
    const response = await instance.post("/payment/no-success", payload);
    return response.data;
  } catch (error) {
    console.log("Error fetching assets:", error);
    throw error;
  }
};

export const CreateOrder = async (
  subjectName,
  subjectAddress,
  subjectCountry,
  orderEmail,
  phoneNo,
  fullLandline,
  reportType,
  repeatDetails,
  additionalAddress,
  name,
  referenceNumber,
  includeReferenceNumber,
  selectedCustomerType,
  currencyId,
  reportManual,
  piFileKey
) => {
  try {
    const authToken = JSON.stringify({
      email: VueCookies.get("email"),
      webToken: VueCookies.get("token"),
    });
    let payload = {
      subjectName: subjectName,
      subjectAddress: subjectAddress,
      subjectCountry: subjectCountry,
      orderEmail: orderEmail,
      orderContactNumber: phoneNo,
      orderLandlineNumber: fullLandline,
      reportType: reportType,
      reportDetails: repeatDetails,
      moreInformation: additionalAddress,
      customerName: name,
      referenceNumber: referenceNumber,
      includeReferenceNumber: includeReferenceNumber,
      customerType: selectedCustomerType,
      piFileKey: piFileKey,
      currencyId: currencyId,
      reportManual: reportManual,
    };
    const response = await instance.post("/orders/create-order", payload, {
      headers: {
        "auth-token": authToken,
      },
    });
    return response.data;
  } catch (error) {
    console.log("Error fetching assets:", error);
    throw error;
  }
};

export const AllCountries = async (products) => {
  try {
    const response = await instance.get(
      `/search/all-countries?noProducts=${products}`
    );
    return response.data;
  } catch (error) {
    console.log("Error fetching assets:", error);
    throw error;
  }
};

export const AllCurrencies = async () => {
  try {
    const response = await instance.get("/payment/all-currencies");
    return response.data;
  } catch (error) {
    console.log("Error fetching assets:", error);
    throw error;
  }
};

export const ProductVariant = async (payload) => {
  try {
    const response = await instance.post("/search/product-variant", payload);
    return response.data;
  } catch (error) {
    console.log("Error fetching assets:", error);
    throw error;
  }
};

export const FileUpload = async (payload) => {
  try {
    const response = await instance.post("/files/upload", payload);
    return response.data;
  } catch (error) {
    console.log("Error fetching assets:", error);
    throw error;
  }
};

export const NewsLetter = async (email) => {
  try {
    let payload = {
      email: email,
    };
    const response = await instance.post("/user/newsletter", payload);
    return response.data;
  } catch (error) {
    console.log("Error fetching assets:", error);
    throw error;
  }
};

export const PasswordAction = async (email, action, secretValue) => {
  try {
    let payload = {
      email: email,
      action: action,
      secret: secretValue !== "" ? secretValue : undefined,
    };
    const response = await instance.post(
      "/user/initiate-password-action",
      payload
    );
    return response.data;
  } catch (error) {
    console.log("Error fetching assets:", error);
    throw error;
  }
};

export const CompletePasswordAction = async (email, token, otp, password) => {
  try {
    let payload = {
      email: email,
      secretValue: token,
      code: otp,
      password: password,
    };
    const response = await instance.post(
      "/user/complete-password-action",
      payload
    );
    return response.data;
  } catch (error) {
    console.log("Error fetching assets:", error);
    throw error;
  }
};

export const UpdateUserDetails = async (payload) => {
  const authToken = JSON.stringify({
    email: VueCookies.get("email"),
    webToken: VueCookies.get("token"),
  });
  try {
    const response = await instance.put("/user/update", payload, {
      headers: {
        "auth-token": authToken,
      },
    });
    return response.data;
  } catch (error) {
    console.log("Error fetching assets:", error);
    throw error;
  }
};

export const CreateTeam = async (payload) => {
  const authToken = JSON.stringify({
    email: VueCookies.get("email"),
    webToken: VueCookies.get("token"),
  });
  try {
    const response = await instance.post("/teams/create-team", payload, {
      headers: {
        "auth-token": authToken,
      },
    });
    return response.data;
  } catch (error) {
    console.log("Error fetching assets:", error);
    throw error;
  }
};

export const InviteMembers = async (payload) => {
  const authToken = JSON.stringify({
    email: VueCookies.get("email"),
    webToken: VueCookies.get("token"),
  });
  try {
    const response = await instance.post("/teams/invite-member", payload, {
      headers: {
        "auth-token": authToken,
      },
    });
    return response.data;
  } catch (error) {
    console.log("Error fetching assets:", error);
    throw error;
  }
};

export const GetDesignationList = async () => {
  const authToken = JSON.stringify({
    email: VueCookies.get("email"),
    webToken: VueCookies.get("token"),
  });
  try {
    const response = await instance.get("/user/get-designations", {
      headers: {
        "auth-token": authToken,
      },
    });
    return response.data;
  } catch (error) {
    console.log("Error fetching assets:", error);
    throw error;
  }
};

export const CheckExistingTeamMembers = async (payload) => {
  const authToken = JSON.stringify({
    email: VueCookies.get("email"),
    webToken: VueCookies.get("token"),
  });
  try {
    const response = await instance.post("/teams/check-member", payload, {
      headers: {
        "auth-token": authToken,
      },
    });
    return response.data;
  } catch (error) {
    console.log("Error fetching assets:", error);
    throw error;
  }
};

export const GetTeamList = async (payload) => {
  const authToken = JSON.stringify({
    email: VueCookies.get("email"),
    webToken: VueCookies.get("token"),
  });
  try {
    const response = await instance.post("/teams/get-teams-of-user", payload, {
      headers: {
        "auth-token": authToken,
      },
    });
    return response.data;
  } catch (error) {
    console.log("Error fetching assets:", error);
    throw error;
  }
};

export const GetTeamMembersList = async (payload) => {
  const authToken = JSON.stringify({
    email: VueCookies.get("email"),
    webToken: VueCookies.get("token"),
  });
  try {
    const response = await instance.post("/teams/get-users-of-team", payload, {
      headers: {
        "auth-token": authToken,
      },
    });
    return response.data;
  } catch (error) {
    console.log("Error fetching assets:", error);
    throw error;
  }
};

export const TeamInviteValidation = async (payload) => {
  try {
    const response = await instance.post("/teams/invite-validation", payload);
    return response.data;
  } catch (error) {
    console.log("Error fetching assets:", error);
    throw error;
  }
};

export const AcceptTeamInvite = async (payload) => {
  const authToken = JSON.stringify({
    email: VueCookies.get("email"),
    webToken: VueCookies.get("token"),
  });
  try {
    const response = await instance.post("/teams/accept-invite", payload, {
      headers: {
        "auth-token": authToken,
      },
    });
    return response.data;
  } catch (error) {
    console.log("Error fetching assets:", error);
    throw error;
  }
};

export const DownloadReportPDF = async (payload) => {
  const authToken = JSON.stringify({
    email: VueCookies.get("email"),
    webToken: VueCookies.get("token"),
  });
  try {
    const response = await instance.post("/files/download-report", payload, {
      responseType: "arraybuffer",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/pdf",
        "auth-token": authToken,
      },
    });
    return response;
  } catch (error) {
    console.log("Error fetching assets:", error);
    throw error;
  }
};

export const RepositoryOrders = async (page) => {
  const payload = {
    page: page,
  };
  const authToken = JSON.stringify({
    email: VueCookies.get("email"),
    webToken: VueCookies.get("token"),
  });
  try {
    const response = await instance.post("/orders/order-repository", payload, {
      headers: {
        "Content-type": "application/json; charset=UTF-8",
        "auth-token": authToken,
      },
    });
    return response.data;
  } catch (error) {
    console.log("Error fetching assets:", error);
    throw error;
  }
};

export const ActiveOrders = async (page) => {
  const payload = {
    page: page,
  };
  const authToken = JSON.stringify({
    email: VueCookies.get("email"),
    webToken: VueCookies.get("token"),
  });
  try {
    const response = await instance.post("/orders/active-orders", payload, {
      headers: {
        "Content-type": "application/json; charset=UTF-8",
        "auth-token": authToken,
      },
    });
    return response.data;
  } catch (error) {
    console.log("Error fetching assets:", error);
    throw error;
  }
};

export const CancelledOrders = async (page) => {
  const payload = {
    page: page,
  };
  const authToken = JSON.stringify({
    email: VueCookies.get("email"),
    webToken: VueCookies.get("token"),
  });
  try {
    const response = await instance.post("/orders/cancelled-orders", payload, {
      headers: {
        "Content-type": "application/json; charset=UTF-8",
        "auth-token": authToken,
      },
    });
    return response.data;
  } catch (error) {
    console.log("Error fetching assets:", error);
    throw error;
  }
};

export const GetBanksList = async (name) => {
  const authToken = JSON.stringify({
    email: VueCookies.get("email"),
    webToken: VueCookies.get("token"),
  });
  try {
    const response = await instance.get(
      `/admin/all-entities?type=BANK,CORPORATE&name=${name}`,
      {
        headers: {
          "auth-token": authToken,
          "Content-Type": "application/json",
        },
      }
    );
    return response.data;
  } catch (error) {
    console.log("Error fetching assets:", error);
    throw error;
  }
};

export const GetRMList = async () => {
  const authToken = JSON.stringify({
    email: VueCookies.get("email"),
    webToken: VueCookies.get("token"),
  });
  try {
    const response = await instance.get("/admin/get-rm-users", {
      headers: {
        "auth-token": authToken,
      },
    });
    return response.data;
  } catch (error) {
    console.log("Error fetching assets:", error);
    throw error;
  }
};

export const UpdateBankDetails = async (payload) => {
  const authToken = JSON.stringify({
    email: VueCookies.get("email"),
    webToken: VueCookies.get("token"),
  });
  try {
    const response = await instance.put("/admin/update-entity", payload, {
      headers: {
        "auth-token": authToken,
        "Content-Type": "application/json",
      },
    });
    return response.data;
  } catch (error) {
    console.log("Error fetching assets:", error);
    throw error;
  }
};

export const AddBank = async (payload) => {
  const authToken = JSON.stringify({
    email: VueCookies.get("email"),
    webToken: VueCookies.get("token"),
  });
  try {
    const response = await instance.post("/admin/add-entity", payload, {
      headers: {
        "Content-type": "application/json",
        "auth-token": authToken,
      },
    });
    return response.data;
  } catch (error) {
    console.log("Error fetching assets:", error);
    throw error;
  }
};

export const GetBranchList = async (id) => {
  const authToken = JSON.stringify({
    email: VueCookies.get("email"),
    webToken: VueCookies.get("token"),
  });
  try {
    const response = await instance.get(`/admin/all-branches?entityId=${id}`, {
      headers: {
        "auth-token": authToken,
        "Content-Type": "application/json",
      },
    });
    return response.data;
  } catch (error) {
    console.log("Error fetching assets:", error);
    throw error;
  }
};

export const UpdateBranchDetails = async (payload) => {
  const authToken = JSON.stringify({
    email: VueCookies.get("email"),
    webToken: VueCookies.get("token"),
  });
  try {
    const response = await instance.put("/admin/update-branch", payload, {
      headers: {
        "auth-token": authToken,
        "Content-Type": "application/json",
      },
    });
    return response.data;
  } catch (error) {
    console.log("Error fetching assets:", error);
    throw error;
  }
};

export const AddBranch = async (payload) => {
  const authToken = JSON.stringify({
    email: VueCookies.get("email"),
    webToken: VueCookies.get("token"),
  });
  try {
    const response = await instance.post("/admin/add-branch", payload, {
      headers: {
        "Content-type": "application/json",
        "auth-token": authToken,
      },
    });
    return response.data;
  } catch (error) {
    console.log("Error fetching assets:", error);
    throw error;
  }
};

export const AddUserToEntity = async (payload) => {
  const authToken = JSON.stringify({
    email: VueCookies.get("email"),
    webToken: VueCookies.get("token"),
  });
  try {
    const response = await instance.put("/admin/link-user-entity", payload, {
      headers: {
        "auth-token": authToken,
        "Content-Type": "application/json",
      },
    });
    return response.data;
  } catch (error) {
    console.log("Error fetching assets:", error);
    throw error;
  }
};

export const AddSupervisor = async (payload) => {
  const authToken = JSON.stringify({
    email: VueCookies.get("email"),
    webToken: VueCookies.get("token"),
  });
  try {
    const response = await instance.put("/admin/add-supervisor", payload, {
      headers: {
        "auth-token": authToken,
        "Content-Type": "application/json",
        Cookie:
          "sails.sid=s%3AG-qR8Ao--WsLB5XOck_dMbYquAEu-A23.0koOoCklyLbpnVTDYFdG%2BlcRiZyxpiKKQt943F0gQD8",
      },
    });
    return response.data;
  } catch (error) {
    console.log("Error fetching assets:", error);
    throw error;
  }
};

export const BulkUserUpload = async (payload, entity) => {
  const authToken = JSON.stringify({
    email: VueCookies.get("email"),
    webToken: VueCookies.get("token"),
  });
  try {
    const response = await instance.post(
      "/admin/bulk-user-upload?entity=" + entity,
      payload,
      {
        headers: {
          "Content-Type": "multipart/form-data",
          "auth-token": authToken,
        },
      }
    );
    return response && response.data;
  } catch (error) {
    console.log("Error fetching assets:", error);
    throw error;
  }
};

export const GetProductConfig = async () => {
  const authToken = JSON.stringify({
    email: VueCookies.get("email"),
    webToken: VueCookies.get("token"),
  });
  try {
    const response = await instance.get("/admin/product-config", {
      headers: {
        "auth-token": authToken,
      },
    });
    return response.data;
  } catch (error) {
    console.log("Error fetching assets:", error);
    throw error;
  }
};

export const GetPendingInvites = async (payload) => {
  const authToken = JSON.stringify({
    email: VueCookies.get("email"),
    webToken: VueCookies.get("token"),
  });
  try {
    const response = await instance.post("/teams/pending-invites", payload, {
      headers: {
        "Content-type": "application/json",
        "auth-token": authToken,
      },
    });
    return response.data;
  } catch (error) {
    console.log("Error fetching assets:", error);
    throw error;
  }
};

// Bulk Order

export const AddToCart = async (
  subjectName,
  subjectAddress,
  subjectCountry,
  orderEmail,
  phoneNo,
  fullLandline,
  reportType,
  repeatDetails,
  additionalAddress,
  name,
  referenceNumber,
  includeReferenceNumber,
  selectedCustomerType,
  currencyId,
  reportManual,
  piFileKey
) => {
  const authToken = JSON.stringify({
    email: VueCookies.get("email"),
    webToken: VueCookies.get("token"),
  });
  let payload = {
    order: {
      subjectName: subjectName,
      subjectAddress: subjectAddress,
      subjectCountry: subjectCountry,
      orderEmail: orderEmail,
      orderContactNumber: phoneNo,
      orderLandlineNumber: fullLandline,
      reportType: reportType,
      reportDetails: repeatDetails,
      moreInformation: additionalAddress,
      customerName: name,
      referenceNumber: referenceNumber,
      includeReferenceNumber: includeReferenceNumber,
      customerType: selectedCustomerType,
      piFileKey: piFileKey,
      currencyId: currencyId,
      reportManual: reportManual,
    },
  };
  try {
    const response = await instance.post("/orders/add-to-cart", payload, {
      headers: {
        "Content-type": "application/json",
        "auth-token": authToken,
      },
    });
    return response.data;
  } catch (error) {
    console.log("Error fetching assets:", error);
    throw error;
  }
};

export const DeleteFromCart = async (payload) => {
  const authToken = JSON.stringify({
    email: VueCookies.get("email"),
    webToken: VueCookies.get("token"),
  });
  try {
    const response = await instance.post("/orders/delete-from-cart", payload, {
      headers: {
        "Content-type": "application/json",
        "auth-token": authToken,
      },
    });
    return response.data;
  } catch (error) {
    console.log("Error fetching assets:", error);
    throw error;
  }
};

export const GetCartItems = async () => {
  const authToken = JSON.stringify({
    email: VueCookies.get("email"),
    webToken: VueCookies.get("token"),
  });
  try {
    const response = await instance.get("/orders/get-cart-items",{
      headers: {
        "auth-token": authToken,
      },
    });
    return response.data;
  } catch (error) {
    console.log("Error fetching assets:", error);
    throw error;
  }
};

export const CheckoutCart = async (payload) => {
  const authToken = JSON.stringify({
    email: VueCookies.get("email"),
    webToken: VueCookies.get("token"),
  });
  try {
    const response = await instance.post("/orders/checkout-cart", payload, {
      headers: {
        "Content-type": "application/json",
        "auth-token": authToken,
      },
    });
    return response.data;
  } catch (error) {
    console.log("Error fetching assets:", error);
    throw error;
  }
};

export const Feedback = async (payload) => {
  const authToken = JSON.stringify({
    email: VueCookies.get("email"),
    webToken: VueCookies.get("token"),
  });
  try {
    const response = await instance.post("/orders/order-feedback", payload, {
      headers: {
        "Content-type": "application/json",
        "auth-token": authToken,
      },
    });
    return response.data;
  } catch (error) {
    console.log("Error fetching assets:", error);
    throw error;
  }
};

export const FindUser = async (payload) => {
  const authToken = JSON.stringify({
    email: VueCookies.get("email"),
    webToken: VueCookies.get("token"),
  });
  try {
    const response = await instance.post("/user/find-user", payload, {
      headers: {
        "Content-type": "application/json",
        "auth-token": authToken,
      },
    });
    return response.data;
  } catch (error) {
    console.log("Error fetching assets:", error);
    throw error;
  }
};

export const ReferUser = async (payload) => {
  const authToken = JSON.stringify({
    email: VueCookies.get("email"),
    webToken: VueCookies.get("token"),
  });
  try {
    const response = await instance.post("/user/share-referral-code", payload, {
      headers: {
        "Content-type": "application/json",
        "auth-token": authToken,
      },
    });
    return response.data;
  } catch (error) {
    console.log("Error fetching assets:", error);
    throw error;
  }
};

export const GetMyReferrals = async () => {
  const authToken = JSON.stringify({
    email: VueCookies.get("email"),
    webToken: VueCookies.get("token"),
  });
  try {
    const response = await instance.get("/user/get-my-referrals", {
      headers: {
        "auth-token": authToken,
      },
    });
    return response.data;
  } catch (error) {
    console.log("Error fetching assets:", error);
    throw error;
  }
};

export const GetDashboardData = async () => {
  const authToken = JSON.stringify({
    email: VueCookies.get("email"),
    webToken: VueCookies.get("token"),
  });
  try {
    const response = await instance.get("/user/dashboard", {
      headers: {
        "auth-token": authToken,
      },
    });
    return response.data;
  } catch (error) {
    console.log("Error fetching assets:", error);
    throw error;
  }
};

export const OrderCountAnalytics = async (payload) => {
  const authToken = JSON.stringify({
    email: VueCookies.get("email"),
    webToken: VueCookies.get("token"),
  });
  try {
    const response = await instance.post("/user/entity-order-count", payload, {
      headers: {
        "Content-type": "application/json",
        "auth-token": authToken,
      },
    });
    return response.data;
  } catch (error) {
    console.log("Error fetching assets:", error);
    throw error;
  }
};

export const RequestReport = async (payload) => {
  const authToken = JSON.stringify({
    email: VueCookies.get("email"),
    webToken: VueCookies.get("token"),
  });
  try {
    const response = await instance.post("/search/request-report", payload, {
      headers: {
        "Content-type": "application/json",
        "auth-token": authToken,
      },
    });
    return response.data;
  } catch (error) {
    console.log("Error fetching assets:", error);
    throw error;
  }
};

export const AddBlog = async (payload) => {
  const authToken = JSON.stringify({
    email: VueCookies.get("email"),
    webToken: VueCookies.get("token"),
  });
  try {
    const response = await instance.post("/blogs/add-blog", payload, {
      headers: {
        "auth-token": authToken,
      },
    });
    return response.data;
  } catch (error) {
    console.log("Error fetching assets:", error);
    throw error;
  }
};

export const GetBlog = async (payload) => {
  const authToken = JSON.stringify({
    email: VueCookies.get("email"),
    webToken: VueCookies.get("token"),
  });
  try {
    const response = await instance.post("/blogs/get-blogs", payload, {
      headers: {
        "auth-token": authToken,
      },
    });
    return response.data;
  } catch (error) {
    console.log("Error fetching assets:", error);
    throw error;
  }
};

export const BlogBannerUpload = async (payload) => {
  try {
    const response = await instance.post("/files/blog-image-upload", payload);
    return response.data;
  } catch (error) {
    console.log("Error fetching assets:", error);
    throw error;
  }
};

export const BulkOrderUpload = async (payload, entity) => {
  const authToken = JSON.stringify({
    email: VueCookies.get("email"),
    webToken: VueCookies.get("token"),
  });
  try {
    const response = await instance.post(
      "/admin/bulk-order-upload?entity=" + entity,
      payload,
      {
        headers: {
          "Content-Type": "multipart/form-data",
          "auth-token": authToken,
        },
      }
    );
    return response && response.data;
  } catch (error) {
    console.log("Error fetching assets:", error);
    throw error;
  }
};

export const AdminSingleOrder = async (payload) => {
  const authToken = JSON.stringify({
    email: VueCookies.get("email"),
    webToken: VueCookies.get("token"),
  });
  try {
    const response = await instance.post("/admin/create-single-order", payload, {
      headers: {
        "auth-token": authToken,
      },
    });
    return response.data;
  } catch (error) {
    console.log("Error fetching assets:", error);
    throw error;
  }
};